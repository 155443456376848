<template>
  <div v-loading="loading" class="merge" element-loading-text="拼命加载中">
    <div class="card inline">
      <details-header :show-popver="false" />
      <el-button type="primary" @click="mergeSubject">合并科目</el-button>
    </div>
    <div class="card card-list">
      <el-table
        :data="tableData"
        class="table-box"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#F2F5F8' }"
      >
        <el-table-column prop="name" label="科 目" width="220" align="center" />
        <el-table-column
          prop="score"
          label="满 分"
          width="220"
          align="center"
        />
        <el-table-column prop="type" label="类 别" width="220" align="center">
          <template slot-scope="{ row }">
            <span :class="row.type === 2 || row.type === 3 ? 'ft-red' : ''">{{
              getType(row.type)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="详 情"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <span>{{ row.description || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操 作" align="center" width="220">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.type === 2 || row.type === 3"
              type="text"
              @click="del(row)"
              >删除合并项目</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="合并科目"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :rules="rules"
    >
      <el-form
        ref="form"
        label-position="right"
        label-width="120px"
        :rules="rules"
        :model="form"
      >
        <el-form-item label="选择创建方式：">
          <el-radio-group v-model="form.type" @change="radioChange">
            <el-radio :label="2">合并科目</el-radio>
            <el-radio :label="3">合并取高科目</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row>
          <el-col :xl="{ span: 11 }" :lg="{ span: 11 }">
            <el-form-item
              label="合并后科目："
              prop="subjectId"
              label-width="120px"
            >
              <el-select v-model="form.subjectId" placeholder="请选择">
                <el-option
                  v-for="item in subjectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="{ offset: 2, span: 11 }" :lg="{ span: 11, offset: 2 }">
            <el-form-item
              :label="form.type === 2 ? '合并后满分：' : '取高后满分：'"
              label-width="110px"
            >
              <el-input
                v-model="form.score"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="max-width: 220px"
                @blur="form.score = $event.target.value"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          label="被合并的科目："
          prop="subjects"
          label-width="140px"
        >
          <el-checkbox-group
            v-if="willBeMergeSubjectList.length"
            v-model="form.subjects"
            @change="checkboxChange"
          >
            <div
              v-for="(item, index) in willBeMergeSubjectList"
              :key="index"
              class="checkbox-inline"
            >
              <el-checkbox :label="item"
                >{{ item.name }}
                <span v-if="form.type === 2"
                  >转换分数：<el-input
                    v-model.number="item.score"
                    style="width: 60px"
                  ></el-input></span
              ></el-checkbox>
            </div>
          </el-checkbox-group>
          <span v-else>暂无</span>
        </el-form-item>
      </el-form>
      <div class="tag">
        <div>1. 合并后满分指的是：勾选的科目合并后分数的满分</div>
        <div>
          2. 新高考模式下，可通过设置合并后满分为选考组合统计修正满分分值
        </div>
        <div>3.转换分数、合并后满分必须是整数</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getsubjectmergelist,
  removesubjectmerge,
  createsubjectmerge,
  getcanmergesubjectlist,
  getcanbemergedsubjectlist,
} from "@/core/api/academic/statistics";
import { getObj } from "@/core/api/exam/exam";
import { subjectCategoryOptions } from "@/core/util/constdata";
import DetailsHeader from "@/components/detailsHeader.vue";
// import { getSubjectList } from "@/core/util/util";
export default {
  name: "Merge",
  components: {
    DetailsHeader,
  },
  data() {
    // let subjectList = getSubjectList(this.$route.query.level);
    return {
      loading: true,
      tableData: [],
      dialogVisible: false,
      subjectCategoryOptions: subjectCategoryOptions,
      subjectOptions: [],
      form: {
        type: 2,
        subjects: [],
        score: 0,
      },
      btnLoading: false,
      rules: {
        subjectId: [
          { required: true, message: "请选择合并后科目", trigger: "change" },
        ],
        subjects: [
          { required: true, message: "请勾选被合并的科目", trigger: "change" },
        ],
      },
      subjectList: [],
      baseObject: [],
      willBeMergeSubjectList: [],
      examInformation: {},
    };
  },
  mounted() {
    this.getData();
    this.getExamObj();
  },
  methods: {
    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
    },
    async getData() {
      const {
        query: { id },
      } = this.$route;
      const res = await getsubjectmergelist({ statId: id });
      this.loading = false;
      this.tableData = res.data.data;
    },
    async getcanbemergedsubjectlist() {
      const {
        query: { id },
      } = this.$route;
      const res = await getcanbemergedsubjectlist({ statId: id });
      this.willBeMergeSubjectList = res.data.data;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.btnLoading = true;
          try {
            const subjects = this.form.subjects.map((item) => {
              return {
                statSubjectId: item.id,
                score: item.score,
              };
            });
            await createsubjectmerge({
              ...this.form,
              subjects: subjects,
              statId: this.$route.query.id,
            });
            this.getData();
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.handleClose();
            this.btnLoading = false;
          } catch {
            this.btnLoading = false;
          }
        }
      });
    },
    radioChange() {
      this.form.subjects = [];
      this.form.score = 0;
    },
    checkboxChange() {
      if (this.form.type === 2) {
        this.form.score = this.form.subjects.reduce((total, item) => {
          return total + item.score;
        }, 0);
      } else if (this.form.type === 3) {
        let scoreArr = this.form.subjects.map((item) => item.score);
        this.form.score = (scoreArr.length && Math.max(...scoreArr)) || 0;
      }
    },
    async mergeSubject() {
      this.subjectList = [];
      const {
        query: { id },
      } = this.$route;
      this.dialogVisible = true;
      this.getcanbemergedsubjectlist();
      const res = await getcanmergesubjectlist({ statId: id });
      this.subjectOptions = res.data.data;
    },
    handleClose() {
      this.dialogVisible = false;
      this.btnLoading = false;
      this.form = {
        type: 2,
        subjects: [],
        score: 0,
      };
    },

    getType(type) {
      let data = subjectCategoryOptions.find((item) => item.value === type);
      return data.label;
    },
    del(row) {
      this.$confirm("确定删除合并项目吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          const res = await removesubjectmerge({ id: row.id });
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.merge {
}
.inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkbox-inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 14px;
  .score {
    display: flex;
    align-items: center;
  }
}
::v-deep .el-button {
  font-size: 14px;
  height: 32px;
}
.table-box {
  ::v-deep td {
    height: 44px;
  }
  ::v-deep th {
    height: 44px;
  }
}
.tag {
  border: 1px dashed #2474ed;
  width: 100%;
  background: #f2f8fe;
  font-size: 14px;
  color: #737677;
  box-sizing: border-box;
  padding: 12px 16px;
  margin: 30px 0;
}
::v-deep .el-dialog {
  max-width: 816px;
  .el-dialog__header {
    padding: 24px 32px 0;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
    }
    .el-dialog__headerbtn {
      right: 29px;
      top: 29px;
    }
  }
  .el-dialog__body {
    padding: 24px 32px;
    .el-form {
      &:last-child {
        // border-bottom: 1px solid #e0e0e0;
      }
    }

    .form-item-box {
      // border-top: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .checkbox-item {
        width: 100%;
        // padding-left: 100px;
        margin-bottom: 8px;
      }
      .el-date-editor.el-input {
        width: 100%;
      }
      .item-box-btn {
        display: flex;
        justify-content: space-between;
        p {
          color: $primary-color;
          font-size: 14px;
          margin-right: 8px;
          margin-left: 8px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .two-box {
      .el-form-item {
        width: 49%;
      }
    }
    .el-form-item {
      padding: 8px 0;
      margin-bottom: 0;
    }
  }
  .el-form-item__label {
    text-align: left;
  }
}
</style>
